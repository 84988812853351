import React, { useState, useEffect } from "react";
import { Table, DatePicker } from "antd";
import callApi from "../../ApiCaller";
import DateRangeSelector from "../../utils/DateRangeSelector";
import "./dailysales.scss";
import "dayjs/locale/cs";
import locale from "antd/es/date-picker/locale/cs_CZ";
import dayjs from "dayjs";

const getCurrentYearDates = () => {
  const currentYear = dayjs().year();
  const startOfYear = dayjs(new Date(currentYear, 0, 1));
  const endOfYear = dayjs(new Date(currentYear, 11, 31));
  return [startOfYear, endOfYear];
};

const Dailysales = () => {
  const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dateRange, setDateRange] = useState(getCurrentYearDates());
  const [currentTotal, setCurrentTotal] = useState(0);
  const [columns, setColumns] = useState([]);
  const [columnWidthsState, setColumnWidthsState] = useState({});

  const MAX_TABLE_WIDTH = 1200;
  const FIXED_COLUMN_WIDTH = 150;

  const calculateColumnWidths = (numOfDynamicColumns) => {
    const totalFixedWidth = FIXED_COLUMN_WIDTH * 2; // Pro "Date" a "Total"
    const availableWidth = MAX_TABLE_WIDTH - totalFixedWidth;
    return availableWidth / numOfDynamicColumns;
  };

  useEffect(() => {
    callApi("daily-sales")
      .then((fetchedData) => {
        const transformedData = transformData(fetchedData);
        setData(transformedData);
        setColumns(generateColumns(transformedData));
        applyDateFilter(transformedData, dateRange);
      })
      .catch((error) => {
        console.error("Error loading daily sales:", error);
      });

    const columnWidth = calculateColumnWidths(columns.length);
    const updatedColumns = columns.map((column) => ({
      ...column,
      width: columnWidth,
    }));
    setColumns(updatedColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const applyDateFilter = (data, dates) => {
    if (dates && dates.length === 2) {
      const startDate = new Date(dates[0]);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(dates[1]);
      endDate.setHours(23, 59, 59, 999);

      const filteredData = data.filter((dataItem) => {
        const createdDate = new Date(dataItem.Date);
        createdDate.setHours(0, 0, 0, 0);
        return createdDate >= startDate && createdDate <= endDate;
      });

      setFilteredData(filteredData);
      const total = filteredData.reduce((sum, { Total }) => sum + Total, 0);
      setCurrentTotal(total);
    } else {
      setFilteredData([]);
      setCurrentTotal(0);
    }
  };

  const transformData = (fetchedData) => {
    const groupedByDate = {};
    fetchedData.forEach(({ Date, PaymentType, Total }) => {
      const dateKey = Date.split(" ")[0];
      if (!groupedByDate[dateKey]) {
        groupedByDate[dateKey] = { Date: dateKey, Total: 0 };
      }
      groupedByDate[dateKey][PaymentType] = Total;
      groupedByDate[dateKey].Total += Total;
    });
    return Object.values(groupedByDate);
  };

  const generateColumns = (transformedData) => {
    const columnSet = new Set(["Date", "Total"]);
    transformedData.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (key !== "Date" && key !== "Total") {
          columnSet.add(key);
        }
      });
    });

    // Požadované pořadí sloupců
    const desiredColumnOrder = [
      "Date",
      "Total",
      "Cash",
      "Card - Comgate",
      "WOLT",
      "FOODORA",
      "Nesnězeno",
      "BOLT",
      "Card - SumUp",
      "*** CARD - old sum up",
      "Corrency 50%",
    ];

    // Seřadíme sloupce podle požadovaného pořadí a zároveň odfiltrujeme ty, které neobsahují žádná data
    const columnsWithValues = desiredColumnOrder.filter((column) => {
      return (
        columnSet.has(column) && transformedData.some((row) => row[column])
      );
    });

    const fixedWidthColumns = ["Date", "Total"]; // Sloupce s pevnou šířkou
    const dynamicColumns = columnsWithValues.filter(
      (column) => !fixedWidthColumns.includes(column)
    );
    const dynamicColumnWidth = calculateColumnWidths(dynamicColumns.length);

    const columnWidths = {};

    const columns = columnsWithValues.map((key) => {
      const width = fixedWidthColumns.includes(key)
        ? FIXED_COLUMN_WIDTH
        : dynamicColumnWidth;
      columnWidths[key] = width;

      return {
        title:
          key === "Date" ? "Datum" : key === "Total" ? "Celková tržba" : key,
        dataIndex: key,
        width: width,
        render: (value) => (key !== "Date" ? currencyFormat(value) : value),
        align: "center",
      };
    });

    setColumnWidthsState(columnWidths);

    return columns;
  };

  const handleDateChange = (dates) => {
    setDateRange(dates);
    applyDateFilter(data, dates);
  };

  const currencyFormat = (amount) => {
    // Zkontrolujte, zda je amount číslo, pokud ne, vraťte "0 Kč"
    if (typeof amount !== "number") {
      return "0 Kč";
    }

    return `${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Kč`;
  };

  // Přidejte tuto funkci do vaší komponenty
  const calculateColumnTotals = () => {
    const totals = {};
    columns.forEach((column) => {
      if (column.dataIndex !== "DaFte" && column.dataIndex !== "Total") {
        totals[column.dataIndex] = filteredData.reduce(
          (sum, item) => sum + (item[column.dataIndex] || 0),
          0
        );
      }
    });
    return totals;
  };

  // Můžete si definovat objekt pro mapování klíčů na šířky

  return (
    <div style={{ width: "75vw" }}>
      <h1 className="dailySalesH1">Denní prodeje</h1>
      <div className="datePickers">
        <RangePicker
          locale={locale}
          onChange={handleDateChange}
          value={dateRange}
          placeholder={["Od", "Do"]}
          className="rangepicker"
        />
        <DateRangeSelector onDateChange={handleDateChange} />
      </div>

      <Table
        dataSource={filteredData}
        columns={columns}
        rowKey="Date"
        pagination={{
          pageSizeOptions: ["10", "20", "50"],
          showSizeChanger: true,
        }}
        footer={() => {
          const columnTotals = calculateColumnTotals();
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  minWidth: "285px",
                }}
              >
                Zobrazená tržba:{" "}
                {currentTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                Kč
              </div>
              {columns.map((column) => {
                if (
                  column.dataIndex !== "Date" &&
                  column.dataIndex !== "Total"
                ) {
                  return (
                    <div
                      key={column.dataIndex}
                      style={{
                        minWidth: columnWidthsState[column.dataIndex] || "auto",
                        textAlign: "center",
                        fontWeight: "500",
                      }}
                    >
                      {currencyFormat(columnTotals[column.dataIndex])}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          );
        }}
      />
    </div>
  );
};

export default Dailysales;
