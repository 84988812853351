import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./actualYearChartBox.scss";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from "recharts";
import { useAuth } from "oidc-react";
import callApi from "../../ApiCaller";

const currentMonth = new Date().getMonth() + 1; // Získání aktuálního měsíce (1 až 12)

const ActualYearChartBox = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const auth = useAuth();
  const userProfile = auth.userData?.profile;
  const isEmployee = userProfile?.[
    "urn:zitadel:iam:org:project:242942895919755267:roles"
  ]?.some((role) => role.employee);
  const isInvestor = userProfile?.[
    "urn:zitadel:iam:org:project:242942895919755267:roles"
  ]?.some((role) => role.investor);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const titleStyle = {
    color: isHovered ? "teal" : "white", // Změna barvy při najetí myší
  };

  const [year2021, setYear2021] = useState(null);
  const [year2022, setYear2022] = useState(null);
  const [year2023, setYear2023] = useState(null);
  const [year2024, setYear2024] = useState(null);
  const [year2025, setYear2025] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data2021 = await fetchYearData(2021);
      const data2022 = await fetchYearData(2022);
      const data2023 = await fetchYearData(2023);
      const data2024 = await fetchYearData(2024);
      const data2025 = await fetchYearData(2025);

      setYear2021(data2021);
      setYear2022(data2022);
      setYear2023(data2023);
      setYear2024(data2024);
      setYear2025(data2025);
    };

    fetchData();
  }, []);

  const fetchYearData = async (year) => {
    try {
      const data = await callApi(`year${year}`);
      return data[`year${year}`];
    } catch (error) {
      console.error(`Error fetching data for year ${year}:`, error);
      return null;
    }
  };

  const percentageChange =
    year2025 !== null && year2024 !== null
      ? ((year2025 - year2024) / year2024) * 100
      : null;

  // Funkce pro zvýraznění aktuálního měsíce
  const renderXAxisTick = (props) => {
    const { x, y, payload } = props;
    const month = parseInt(payload.value, 10);

    // Zvýraznění aktuálního měsíce
    const isCurrentMonth = month === currentMonth;
    const textStyle = {
      fontWeight: isCurrentMonth ? "bold" : "normal",
      fill: isCurrentMonth ? "white" : "#666",
    };

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" style={textStyle}>
          {payload.value}
        </text>
      </g>
    );
  };

  // Upravit styl pro osu Y
  const yAxisStyle = {
    fontSize: 12, // Změnit velikost fontu na ose Y
  };

  return (
    <div className="bigChartBox">
      {!(isEmployee || isInvestor) ? (
        <Link
          to="/documents"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={titleStyle}
        >
          <h1>Roční tržby</h1>
        </Link>
      ) : (
        <h1>Roční tržby</h1>
      )}
      <div className="chart">
        <ResponsiveContainer width="99%" height="100%">
          <AreaChart
            data={[
              { name: "2021", total: year2021 },
              { name: "2022", total: year2022 },
              { name: "2023", total: year2023 },
              { name: "2024", total: year2024 },
              { name: "2025", total: year2025 },
            ]}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <XAxis dataKey="name" tick={renderXAxisTick} />
            <YAxis style={yAxisStyle} />
            <Legend />
            <Tooltip
              content={(props) => {
                const { active, payload } = props;
                if (active && payload && payload.length > 0) {
                  // Získání hodnoty tržby za daný rok
                  const yearValue = payload[0].value;

                  // Vlastní funkce pro formátování čísel v českém formátu
                  const formatNumber = (value) => {
                    return value
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                  };

                  return (
                    <div className="custom-tooltip">
                      <p className="tooltip-label">{`${
                        payload[0].name
                      }: ${formatNumber(yearValue)} Kč`}</p>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Area
              type="monotone"
              dataKey="total"
              stroke="#82ca9d"
              strokeWidth={2}
              dot={false}
              name="Tržba"
              fill="none" // Nastavit fill na "none" pro ohraničení oblasti
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className="texts" style={{ textAlign: "center" }}>
        <span className="duration">Tento rok </span>
        <span
          className="percentage"
          style={{
            color:
              percentageChange !== null
                ? percentageChange.toFixed(2)
                : null < 0
                ? "tomato"
                : "limegreen",
          }}
        >
          {percentageChange !== null ? percentageChange.toFixed(2) : null}%
        </span>
      </div>
    </div>
  );
};

export default ActualYearChartBox;
