import React from "react";
import PatchNote from "../../components/PatchNote/PatchNote";

const PatchNotes = () => {
  const patchNotesData = [
    {
      title: "v1.0.1 - 8.1.2025",
      notes: [
        "Přidaný Patch Notes",
        "Přidaný barvy a rok 2025 do grafů",
        "Přídaný rozdíl mezi kartou a terminálem v Denní tržbě, pro zjištění rozdílu mezi kartou v kase a terminálem",
        "Přidaná FOODORA do daily sales",
      ],
    },
    {
      title: "v1.0.0 2023-2024",
      notes: ["Průběžný vývoj"],
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <h1 style={{ paddingBottom: "10px" }}>Patch Notes</h1>
      <PatchNote versions={patchNotesData} />
    </div>
  );
};

export default PatchNotes;
