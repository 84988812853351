import React from "react";
import { Timeline } from "antd";
import "./PatchNote.scss";

const PatchNote = ({ versions }) => {
  return (
    <div className="patch-notes-timeline">
      <Timeline>
        {versions.map((version, index) => (
          <Timeline.Item key={index} color="blue">
            <div className="patch-note-version">
              <h3 className="patch-note-title">{version.title}</h3>
              <ul className="patch-note-list">
                {version.notes.map((note, noteIndex) => (
                  <li key={noteIndex} className="patch-note-item">
                    {note}
                  </li>
                ))}
              </ul>
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  );
};

export default PatchNote;
